import React, {useState, useEffect} from "react"
import {Link, graphql, navigate} from "gatsby"
import ProductStore from '../store/product';
import {observer} from 'mobx-react'
import {ModalRoutingContext} from "gatsby-plugin-modal-routing-3"
import Img from "gatsby-image"
import Picture from "../components/picture"
import {normalizeSrcset} from "../utils"

const ProductModal = observer(({location}) => {
  const productName = location.pathname.split('/').filter((item) => item !== "").pop();
  const products = ProductStore.getProducts;
  const product = products.find((item) => `${item.name?.replace(/[^A-Z0-9]+/gi, "-").toLowerCase()}-${item?.productColor?.name.replace(/[^A-Z0-9]+/gi, "-").toLowerCase()}` === productName);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    //if (modalOpen) return (document.documentElement.style.overflow = "hidden")
  }, [modalOpen])
  useEffect(() => {
    ProductStore.fetchAll()
    return () => {
      setModalOpen(false)
      //document.documentElement.style.overflow = "auto"
    }
  }, [])
  if (!product) {
    if (products.length > 0) navigate('/catalogue')
    return <div />
  }
  const color = product?.productColor?.name
    .split(" ")
    .map(color => color[0] + color.toLowerCase().slice(1))
    .join(" ")

  return (
    <ModalRoutingContext.Consumer>
      {({modal, closeTo}) => {
        return (
          <div
            onLoad={() => setModalOpen(modal)}
            className={`relative pb-32 max-w-screen-sm mx-auto lg:max-w-screen-lg  ${!modal && "pt-16"
              } `}
          >
            <div className="flex justify-between align-center">
              <div className="w-3/4 lg:w-1/2">
                <h1 className="text-2xl lg:text-4xl w-3/2">{product?.name}</h1>
                <p className="opacity-50 mt-2 lg:text-2xl lg:inline-block lg:mt-3">
                  {product?.pricePoint ? Number(product?.pricePoint?.name)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : '--.-'}{" "}
                  €
                </p>
              </div>
              {modal ? (
                <Link
                  to={closeTo}
                  state={{
                    noScroll: true
                  }}
                  className="absolute left-1/2 bottom-4 transform -translate-x-1/2 w-16 lg:static lg:translate-x-0"
                >
                  <img src="/close.svg" alt="" />
                </Link>
              ) : (
                <Link
                  to="/catalogue"
                  className="fixed z-50 left-1/2 bottom-4 transform -translate-x-1/2 w-16 h-16 lg:fixed lg:translate-x-0 lg:top-8 lg:left-unset lg:right-8 lg:inline"
                >
                  <img src="/close.svg" alt="" />
                </Link>
              )}
            </div>
            <div className="w-full mt-10 mb-10 lg:mt-16 lg:mb-32">
              <div className="flex flex-col-reverse lg:space-x-32 lg:flex-row lg:items-start lg:justify-between">
                <p className="lg:w-1/2">{product?.story}</p>
                <div className="lg:w-5/12">
                  <p className="mb-6 lg:mb-0">
                    {color}{" "}
                    {`color available`}
                  </p>
                </div>
              </div>
              <ul className="mt-10 list-disc list-inside">
                {product?.fb1 && <li className="mb-3">{product.fb1}</li>}
                {product?.fb2 && <li className="mb-3">{product.fb2}</li>}
                {product?.fb3 && <li className="mb-3">{product.fb3}</li>}
                {product?.fb4 && <li className="mb-3">{product.fb4}</li>}
                {product?.fb5 && <li className="mb-3">{product.fb5}</li>}
                {product?.fb6 && <li className="mb-3">{product.fb6}</li>}
                {product?.fb7 && <li className="mb-3">{product.fb7}</li>}
                {product?.fb8 && <li className="mb-3">{product.fb8}</li>}
                {product?.fb9 && <li className="mb-3">{product.fb9}</li>}
                {product?.fb10 && <li className="mb-3">{product.fb10}</li>}
              </ul>
            </div>
            {product.assets.map(asset => {
              const normAsset = normalizeSrcset(asset?.cached)
              return (
                <div key={asset.id}>
                  <Picture
                    className={`mx-auto mb-12 max-w-full`}
                    srcset={normAsset}
                  />
                </div>
              )
            })}
          </div>
        )
      }}
    </ModalRoutingContext.Consumer>
  )
})

export default ProductModal
